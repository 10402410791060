@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
  Note: The styles for this gradient grid background is heavily inspired by the creator of this amazing site (https://dub.sh) – all credits go to them! 
*/

.main {
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  padding: 120px 24px 160px 24px;
  pointer-events: none;
}

.main:before {
  background: radial-gradient(circle, rgba(2, 0, 36, 0) 0, #fafafa 100%);
  position: absolute;
  content: "";
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
}

/* .main:after {
  content: "";
  background-image: url("/assets/images/grid.svg");
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0.4;
  filter: invert(1);
} */

.gradient {
  height: fit-content;
  z-index: 3;
  width: 100%;
  max-width: 640px;
  background-image: radial-gradient(
      at 27% 37%,
      hsla(215, 98%, 61%, 1) 0px,
      transparent 0%
    ),
    radial-gradient(at 97% 21%, hsla(125, 98%, 72%, 1) 0px, transparent 50%),
    radial-gradient(at 52% 99%, hsla(354, 98%, 61%, 1) 0px, transparent 50%),
    radial-gradient(at 10% 29%, hsla(256, 96%, 67%, 1) 0px, transparent 50%),
    radial-gradient(at 97% 96%, hsla(38, 60%, 74%, 1) 0px, transparent 50%),
    radial-gradient(at 33% 50%, hsla(222, 67%, 73%, 1) 0px, transparent 50%),
    radial-gradient(at 79% 53%, hsla(343, 68%, 79%, 1) 0px, transparent 50%);
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  filter: blur(100px) saturate(150%);
  top: 80px;
  opacity: 0.15;
}

@media screen and (max-width: 640px) {
  .main {
    padding: 0;
  }
}

/* Modal CSS */

#root.active-modal {
  overflow: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal,
 .overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 9;
  backdrop-filter: blur(1px);
  display: flex;
  justify-content: center;
  align-items: center;
   
}
 

.overlay {
  background: rgba(49,49,49,0.7);
  opacity: 10%;
  z-index: 8;
  
}
.modal-content {
  width: 40vw;
  margin-top: 50px;
  background: #f1f1f1;
  line-height: 1.4;
  border-radius: 5px;
  max-width: 600px;
  min-width: 290px;
  z-index: 10;
  padding: 5px;
  opacity: 1;
}



.close-modal {
  top: 10px;
  right: 10px;
  padding: 5px 7px;
  animation: fadeOut .9s ease-in-out;   

} 


@keyframes fade {
    0%{
       opacity: 0;
    }100%{
      opacity: 1;
    }
}
@keyframes fadeOut {
    0%{
       opacity: 1;
       /* left: 50%; */
    }100%{
      opacity: 0;
      /* left: 50%; */
    }
}


/* .fade{
  animation: fadeit 0.5s ease-in-out;
} */

@keyframes fade2{
  0%{
    left:0;
    /* opacity: 1; */
    transform: translate(0);
  }
  100%{
    left:-300px;
    transform: translate(-100%);
    /* opacity: 0; */
    display: hidden;
  }
}
.autoFade{
  animation: fadeit 0.5s ease-in-out;
}

@keyframes fadeit{
  0%{
    left:0;
    /* opacity: 1; */
    transform: translate(0);
  }
  100%{
    left:-300px;
    transform: translate(-100%);
    /* opacity: 0; */
    display: hidden;
  }
}

div .MuiInputBase-root{
  /* height: 43px; */
}
/* Tailwind Styles */

/* .app {
  @apply relative z-10 flex justify-center items-center flex-col max-w-7xl mx-auto sm:px-16 px-6;
} */

.title{
  @apply font-semibold text-orange-500 text-3xl
}
.book_btn{
  @apply rounded-full border border-orange-400 bg-orange-400 py-1.5 px-5 text-white transition-all hover:bg-orange-500 text-center text-sm flex items-center justify-center;
}
.close_btn{
  @apply rounded-full border border-red-400 bg-red-500 py-1.5 px-5 text-white transition-all hover:bg-red-700 hover:text-white text-center text-sm  flex items-center justify-center;
}
.black_btn {
  @apply rounded-full border border-black bg-black py-1 px-5 text-white transition-all hover:bg-white hover:text-black text-center text-sm  flex items-center justify-center;
}
.carousel_btn{
  @apply rounded-r-full rounded-l-md bg-orange-400 hover:bg-orange-500 py-1.5 px-5 text-white transition-all
}
.outline_btn {
  @apply rounded-full border md:mt-[2px] mt-4 border-gray-100 hover:border-black bg-transparent py-1.5 px-5 text-white transition-all hover:bg-black hover:text-white text-center text-sm  flex items-center justify-center;
}
.download_btn {
  @apply w-60 my-2 rounded-md border border-black hover:border-black shadow-lg bg-transparent py-1.5 px-5 transition-all hover:bg-black hover:text-white text-center font-semibold text-lg flex items-center justify-center;
} 

.head_text {
  @apply mt-5 z-30 text-5xl font-extrabold leading-[1.15] text-gray-50 sm:text-6xl; 
}

.orange_gradient {
  @apply bg-gradient-to-r from-amber-500 via-orange-600 to-yellow-500 bg-clip-text text-transparent;
}
.list_items {
    @apply mt-2 mx-1 block py-2 px-3 text-white rounded hover:bg-black md:hover:bg-transparent md:border-0 hover:text-white md:p-0 dark:text-white md:hover:text-gray-900 md:dark:hover:bg-transparent;
} 
.green_gradient {
  @apply bg-gradient-to-r from-green-400 to-green-500 bg-clip-text text-transparent;
}

.blue_gradient {
  @apply bg-gradient-to-r from-blue-600 to-cyan-600 bg-clip-text text-transparent;
}

.package_card{
   @apply h-72 w-56 flex relative justify-center gap-3
}

.desc {
  @apply mt-5 text-lg text-white pb-5 sm:text-xl max-w-2xl;
}

.search_input {
  @apply block my-5 w-[90%] md:w-[60%] rounded-md border border-gray-200 bg-white py-2.5 pl-5 pr-12 text-sm shadow-lg font-medium focus:border-orange-400 focus:outline-none focus:ring-0;
}

.copy_btn {
  @apply w-7 h-7 rounded-full bg-white/10 shadow-[inset_10px_-50px_94px_0_rgb(199,199,199,0.2)] backdrop-blur flex justify-center items-center cursor-pointer;
}

.glassmorphism {
  @apply rounded-xl border border-gray-200 bg-white/20 shadow-[inset_10px_-50px_94px_0_rgb(199,199,199,0.2)] backdrop-blur p-5;
}

.prompt_layout {
  @apply space-y-6 py-8 sm:columns-2 sm:gap-6 xl:columns-3;
}
/* Background-Image */
.bg-image{
  background-image: url('../assets/images/ujjain-mahakal-bg.jpg');

  /* Add the blur effect */
  
  
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* filter: blur(5px) saturate(70%);
  -webkit-filter: blur(8px); */
}

.imgShadow{
  position: relative;
  -moz-box-shadow: inset 10px 10px 50px #fff;
  -webkit-box-shadow: inset 10px 10px 50px #fff;
  box-shadow: inset 0 0 10px 10px rgba(246, 218, 33, 1);
}
.imgShadow::after{
  content: "";
  position: absolute;
  inset: 0;
  box-shadow: inset 0 0 10px 10px rgba(246, 218, 33, 1);
}


/* Feed Component */
.feed {
  @apply mt-16 mx-auto w-full max-w-xl flex justify-center items-center flex-col gap-2;
}

/* Form Component */
.form_textarea {
  @apply w-full flex rounded-lg h-[200px] mt-2 p-3 text-sm text-gray-500 outline-0;
}

.form_input {
  @apply w-full flex rounded-lg mt-2 p-3 text-sm text-gray-500 outline-0;
}

/* Nav Component */
.logo_text {
  @apply max-sm:hidden font-semibold text-lg text-black tracking-wide;
}

.dropdown {
  @apply absolute right-0 top-full mt-3 w-full p-5 rounded-lg bg-white min-w-[210px] flex flex-col gap-2 justify-end items-end;
}

.dropdown_link {
  @apply text-sm  text-gray-700 hover:text-gray-500 font-medium;
}
/* PromptCard Component */
.prompt_card {
  @apply flex-1 break-inside-avoid rounded-lg border border-gray-300 bg-white/20 bg-clip-padding p-6 pb-4 backdrop-blur-lg backdrop-filter md:w-[360px] w-full h-fit;
}

.flex-center {
  @apply flex justify-center items-center;
}

.flex-start {
  @apply flex justify-start items-start;
}
.package_booking_nav_li{
  @apply px-5 mx-5 text-gray-600 cursor-pointer
}
.package_booking_nav_li span{
  color: black;
}

.flex-end {
  @apply flex justify-end items-center;
}

.flex-between {
  @apply flex justify-between items-center;
}

.header_span::before{
  display: inline-block;
  content: "";
  width: 5px;
  height: 5px;
  background-color: #4a4a4a;
  border-radius: 50%;
  margin-bottom: 2px;
}



.myCarouselBtn>.react-multi-carousel-list>button{
     background: #979fa8b6;     
     display: none;
}

.packageCardAnim{
   transition: all .4s ease-in-out;
  
}
.packageCardAnim:hover>.animPlace{
   background: #df8b1ee4;
   backdrop-filter: blur(5px);
}
.packageCardAnim:hover>.animPlace>p{
   color: white;
}


.react-multi-carousel-list >.react-multiple-carousel__arrow{
   z-index: 10;
}